* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-size: 16px;
  --rem-base-size: 16;
}

a {
  color: inherit;
  text-decoration: none;
}

@media only screen and (max-width: 375px) {
  html,
  body {
    font-size: 12px !important;
    --rem-base-size: 12;
  }
}

@media only screen and (min-width: 376px) and (max-width: 1023px) {
  html,
  body {
    font-size: 14px !important;
    --rem-base-size: 14;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  html,
  body {
    font-size: 12px !important;
    --rem-base-size: 12;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1500px) {
  html,
  body {
    font-size: 14px !important;
    --rem-base-size: 14;
  }
}
